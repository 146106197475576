Payment = {
  addPayeeAddress: function(){
    $(document).on('click', 'add-payor-link', function(){
      $('#add-payee-address').trigger('click')
      $(this).attr('disabled', 'true')

    })
  },
  setupDynamicSearch: function(){
    $('#accounts_payments_payor_id').select2()
    $('#accounts_payments_payor_id').prepend(new Option('Add New Payor', 'new-payor', false, false))
    $(document).on('select2:select', '#accounts_payments_payor_id', function(e){
      if (e.params.data.id == 'new-payor'){
        $.ajax({
          url: '/payors/new',
          dataType: 'script',
        })
      }
    })
  },
  dismissNewPayorModal: function(){
    $(document).on('click','#dismiss-payor-modal-button', function(){
      $('#accounts_payment_payor_id').val('Ui').trigger('change')
    })
  }
}
