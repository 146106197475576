Reminder =

  show: ->
    $(document).on 'change', '.time_in_words_select', (e) ->
      select = $(e.target)
      if select.val() == '0'
        custom_field = select.closest('.set_reminder').find('.custom_reminder_time_select')
        custom_field.show('100')
        custom_field.find('.reminder_time_number').val('3')
        custom_field.find('.reminder_time_magnitude').val('hours')
        select.closest('.time_word_select').fadeOut('100')


Reminder.show()
