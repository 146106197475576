# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/
# @Note =
#
#   submitForm: ->
#     $(document).on 'keydown', '#note_particulars', (e) ->
#       if e.keyCode == 13 && $(this).val().length > 1
#         e.preventDefault()
#         form = $(this).closest('form')
#         form.submit()
#         $('#note_particulars').prop('readonly', true)
#
# $(document).on 'ready', ->
#   Note.submitForm()

Note =

  newOrder: ->
    $(document).on 'keydown', '.notes_court_note_orders_order > textarea', (e) ->
      if e.which == 10 || e.which == 13
        e.preventDefault()
        $('#new-order-button').click()

    $(document).on 'keyup', '.notes_court_note_orders_order > textarea', (e) ->
      if e.keyCode == 8
        if $(e.currentTarget).val() == '' && $('#orders_list').children().length > 1
          $(e.currentTarget).parent().parent().parent().find('a').click()

    $(document).on 'cocoon:after-insert', '#orders_list', (e, addedOrder) ->
       addedOrder.find('textarea').focus()

    $(document).on 'cocoon:after-remove', '#orders_list', () ->
      $('#orders_list').children().last().find('textarea').focus()

Note.newOrder()
