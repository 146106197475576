// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require.context('../images', true);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
const flatpickr = require("flatpickr");
const moment = require('moment');
import 'bootstrap/dist/js/bootstrap';
import '../stylesheets/application.scss';
import select2 from 'select2';
import 'dropzone';
import '../src/cocoon.js';
import '../src/init.js';
import '../src/activities.js';
import '../src/alerts.js';
import '../src/bills.coffee';
import '../src/calendar.js';
import '../src/chart.js';
import '../src/clients.js';
import '../src/client_expenses.js';
import '../src/comments.js';
import '../src/dashboard.js';
import '../src/documents.js';
import '../src/duration.coffee';
import '../src/events.js';
import '../src/extensions.coffee';
import '../src/followings.coffee';
import '../src/form.js';
import '../src/google.coffee';
import '../src/individuals.coffee';
import '../src/invoices.js';
import '../src/layouts.coffee';
import '../src/logs.coffee';
import '../src/matters.coffee';
import '../src/notes.coffee';
import '../src/notifications.coffee';
import '../src/onboarding.coffee';
import '../src/organisations.coffee';
import '../src/parties.coffee';
import '../src/payees.js';
import '../src/payors.js';
import '../src/payments.js';
import '../src/reminder.coffee';
import '../src/sales_receipts.js';
import '../src/quotations.js';
import '../src/search.coffee';
import '../src/static_pages.coffee';
import '../src/tasks.coffee';
import '../src/timer.coffee';
import Chart from 'chart.js/auto';

window.jQuery = jQuery
window.$ = $
