Payee = {
  checkIndividuals: ()=>{
    $(document).on('keyup', '#individual_first_name.payee_name, #individual_last_name.payee_name', function(){
      $.ajax({
        url: '/payees/individuals/existing',
        dataType: 'script',
        data: { q: `${$('#individual_first_name').val()} ${$('#individual_last_name').val()}`}
      })
    })
  },
  checkCompanies: ()=>{
    $(document).on('keyup', '#company_name.payee_name', ()=>{
      $.ajax({
        url: '/payees/companies/existing',
        dataType: 'script',
        data: { q: `${$('#company_name').val()}` }
      })
    })
  },
  checkGovernmentDepartments: ()=>{
    $(document).on('keyup', '#government_department_name.payee_name', ()=>{
      $.ajax({
        url: '/payees/government_departments/existing',
        dataType: 'script',
        data: { q: `${$('#government_department_name').val()}` }
      })
    })
  },
  addNewPayee: ()=>{
    $(document).on('select2:select', '.payee-select', function(e){
      if (e.params.data.id == 'new-payee'){
        $.ajax({
          url: '/payees/new',
          dataType: 'script',
        })
      }
    })
  }
}

$(document).on('turbolinks:load', ()=>{
  Payee.checkIndividuals()
  Payee.checkCompanies()
  Payee.checkGovernmentDepartments()
  Payee.addNewPayee()
})

