Comment = {
  formSubmission: function(){
    $('document').on('keydown', '#comment_body', function(e){
      if (e.keyCode == 13){
        e.preventDefault()
        let form = $(this).closest('form')
        form.submit()
        $('#comment_body').prop('readonly', true)
      }
    })
  }
}

CommentPoller = {
  poll: function(){
    setTimeout(CommentPoller.request,30000)
  },

  request: function(){
    $.getJSON($('#comments').data('url'), {after:  $('.comment').last().data('id')})

  },

  addComments: function(){
    if ($(comments).length > 0){
      $('comments').append($(comments).hide())
      if ($('.comment').last().is(':hidden')){
        $('#showcomments').show()
      }
    }
  },

  showComments: function(e){
    e.preventDefault()
    $('.comment').show('slow')
    $('#showComments').hide()
  }
}

Comment.formSubmission()