TodaysAgenda =
  tabbedNavigation: ->
    $(document).on 'click', '.today-nav', (e) ->
       e.preventDefault()
       unless $(this).hasClass('active')
         current_pane = $('#todays_agenda').find('.active-item')
         current_link = $('#todays_agenda').find('.active')
         current_pane.removeClass('active-item')
         current_link.removeClass('active')
         pane = $(this).attr('href')
         $(this).addClass('active')
         $("#{pane}").addClass('active-item')

TodaysAgenda.tabbedNavigation()

Sidebar =

  TOGGLE: off

  hide: ->
    $('#sidebar').animate 'left': '-247px', 200;
    $('.main').animate 'left': '0', 200
    Sidebar.TOGGLE = off

  display: ->
    $('#sidebar').animate 'left': '0', 200
    $('.main').animate 'left': '247px', 200
    Sidebar.TOGGLE = on

  toggleSideBar: ->
    $(document).on 'click', '#menu_toggle', (e) ->
      e.preventDefault()
      if Sidebar.TOGGLE == off then Sidebar.display() else Sidebar.hide()

  responsiveMainBody: ->
    $(document).on 'click', '.main', ->
      if Sidebar.TOGGLE == on && $(window) < 992 then $('#menu_toggle').trigger('click')

  responsiveSidebar: ->
    $(window).resize ->
      if $(window).width() > 992 && $('#sidebar').css('left') == '-247px'
        Sidebar.display()
      else if $(window).width() < 992 && $('#sidebar').css('left') == '0px'
        Sidebar.hide()
      else
        console.log("Works!")


Sidebar.toggleSideBar()
Sidebar.responsiveSidebar()
Sidebar.responsiveMainBody()
