# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/
Matter =

  toggle: false

  privacyMenuOpen: ->
    $('#privacy_options').slideUp('slow');
    $('#current_privacy').removeClass('privacy_menu_open')
    $('#privacy_caret').html("<i class='fa fa-sort-desc'></i>")

  privacyMenuClose: ->
    $('#privacy_options').slideDown('slow')
    $('#current_privacy').addClass('privacy_menu_open')
    $('#privacy_caret').html("<i class='fa fa-sort-asc'></i>")

  toggleMenu: ->
    if @toggle == false
      @privacyMenuOpen()
      @toggle = true
    else
      @privacyMenuClose()
      @toggle = false

  similarNameSearch: (client, name) ->
    $.get('/matters/similar_names', c: client, q: name)


  autocompleteMatters: ->
    $(document).on 'focusin', '#matter_query', ->
      $('#matter_query').autocomplete
        source: (request, response) ->
          $.ajax
            url: '/matters',
            dataType: "json",
            data:
              q: request.term
            beforeSend: ->
              $('#matter_query_loading').show()
            success: (data) ->
              response(data)
              $('#matter_query_loading').fadeOut('slow')
              $('.ui-helper-hidden-accessible').remove()
        minLength: 2,
      .autocomplete("instance")._renderItem = (ul, item) ->
        $('.matters_pagination').fadeOut(200)
        $('#matter_listings').empty()
        return $("<tr>")
          .append("<td colspan='3'> <a href='#{item.path}'>#{item.title}</a></td>
                  <td><a href='#{item.client_path}'>#{item.client}</a></td>
                  <td>#{item.date_added}</td>")
          .appendTo("#matter_listings")

  resetMatters: ->
    $(document).on 'keyup', '#matter_query', (e) ->
      if e.keyCode == 8
        if $('#matter_query').val().length == 0
          $('.matters_pagination').show(100)
          $.ajax
            url: '/matters',
            dataType: 'script'
        $('.ui-helper-hidden-accessible').remove()

  matterBudgetForm: ->
    $(document).on 'change', '.matter_budget_field', (e) ->
      invoiceForm = $(e.currentTarget).parents('form')[0]
      Rails.fire(invoiceForm, 'submit')

Matter.autocompleteMatters()
Matter.resetMatters()
Matter.matterBudgetForm()
$(document).on 'click', '#current_privacy', ->
  Matter.toggleMenu()
