Search =

  debounce: (func, threshold, execAsap) ->
    timeout = null
    (args...) ->
      obj = this
      delayed = ->
        func.apply(obj, args) unless execAsap
        timeout = null
      if timeout
        clearTimeout(timeout)
      else if (execAsap)
        func.apply(obj, args)
      timeout = setTimeout delayed, threshold || 100

  dismissMenu: ->
    $(document).on 'click', (e) ->
      if $('#search_wrapper').hasClass('search-open')
        Search.closeMenu() unless e.target == $('#search_wrapper')

  closeMenu: ->
      $('#search_results').empty()
      $('#search_results').fadeOut(100)
      $('#search_wrapper').removeClass('search-open')

  noResults: ->
    $('#search_results')
      .empty()
      .html("<div class='no_content'><span class='text_info'>No Search results</span></div>")

  constructClient: (client) ->
    body = $("<div class='search-item'></div>")
    heading = "<a href='#{client.link}'>#{client.name.toUpperCase()}</a> &bull; #{client.matter_count}"
    body.append(heading)
    if client['matters'].length > 0
      matter_listings = $('<ul></ul>')
      client['matters'].forEach (item, i) ->
        listing = $("<li><a href='#{item.matter_link}'>#{item.matter_name}</a></li>")
        matter_listings.append(listing)
      body.append(matter_listings)
    body.appendTo('#search-clients')

  constructMatter: (matter) ->
    body = $("<div class='search-item'></div>")
    content = """
              <a href='#{matter.link}'>#{matter.title}</a><br>
              #{matter.tasks} &bull; #{matter.notes} &bull;
              #{matter.hours} &bull; #{matter.documents}
              """
    body.append(content)
    body.appendTo('#search-matters')

  constructDocument: (document) ->
    body = $("<div class='search-item'></div>")
    content = "<i class='fa fa-file-o'></i> <a href='#{document.link}'>#{document.title}</a>"
    body.append(content)
    body.appendTo('#search-documents')

  constructUser: (user) ->
    body = $("<div class='search-item'></div>")
    content = "<a href='#{user.link}'>#{user.name.toUpperCase()}</a><br>#{user.role} &bull; <a href='mailto:#{user.email}'>#{user.email}</a>"
    body.append(content)
    body.appendTo('#search-users');

  constructResults: (data) ->
    $('#search_results').empty()
    $('#search_results').show(100)
    $('#search_wrapper').addClass("search-open")

    if data['clients'].length > 0
      clients = $("<div class='search-group' id='search-clients'><h4><i class='fa fa-users'></i> Clients</h4></div>")
      $('#search_results').append(clients)
      @constructClient(client) for client in data['clients']
    if data['matters'].length > 0
      matters = $("<div class='search-group' id='search-matters'><h4><i class='fa fa-folder'></i> Matters</div>")
      $('#search_results').append(matters)
      @constructMatter(matter) for matter in data['matters']
    if data['users'].length > 0
      users = $("<div class='search-group' id='search-users'><h4><i class='fa fa-users'></i> Users</div>")
      $('#search_results').append(users)
      @constructUser(user) for user in data['users']
    if data['documents'].length > 0
      documents = $("<div class='search-group' id='search-documents'><h4><i class='fa fa-file-o'></i> Documents</div>")
      $('#search_results').append(documents)
      @constructDocument(item) for item in data['documents']

  search: ->
    $(document).on 'keyup', '#query', (e) ->
      Search.debounce( Search.query(e), 250)

  query: (target) ->
    if target.keyCode == 8 && $('#query').val().length == 0
      Search.closeMenu()
    else
      $.ajax
        url: '/search',
        dataType: 'json',
        data:
          query: $('#query').val()
        beforeSend: ->
          $('#global_search_spinner').show()
        success: (data) ->
          $('#global_search_spinner').fadeOut(100)
          if data['results'] == true
            Search.constructResults(data)
          else
            Search.noResults()

Search.search()
Search.dismissMenu()
