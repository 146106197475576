import Chart from 'chart.js/auto';

document.addEventListener('turbolinks:load', ()=>{
  var ctx = document.getElementById('paymentChart');
  if (ctx != null) {
    console.log(ctx.dataset.labels)
    var myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: JSON.parse(ctx.dataset.labels),
        datasets: [
          {
            label: 'Invoices Issued',
            data: JSON.parse(ctx.dataset.invoices),
            backgroundColor: 'rgba(254,181,4,1)'
          },
          {
          label: 'Payments Received',
          data: JSON.parse(ctx.dataset.receipts),
          backgroundColor: 'rgba(45,44,186,1)'
          }
        ]
      }
    })
  }
})
