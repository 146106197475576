# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

$(document).on 'mouseenter', '.unfollow_button', ->
  $(this).
    removeClass('btn-primary').
    addClass('btn-danger').
    text("Unfollow")
    
$(document).on 'mouseout', '.unfollow_button', ->
  $(this).
    removeClass('btn-danger').
    addClass('btn-primary').
    text("Following")
