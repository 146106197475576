# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/
billWizard = ->
  currentPane = $('.pane-visible')
  nextPane = currentPane.next()


  $(document).on 'click', '#next-bill-pane', (e) ->
    e.preventDefault()
    alert()
    currentPane.removeClass('pane-visible')
    nextPane.addClass('pane-visible')
    currentPane = nextPane
    nextPane = currentPane.next()

$('#billWizardModal').on 'shown.bs.modal', ->
  billWizard()
