Dashboard = {

  displayNoteForm: ()=>{
    $(document).on('keyup', '#note_particulars', ()=>{
      if ($('#note_particulars').val().length > 0){
        Dashboard.showExtraNoteFields();
      } else {
        Dashboard.showExtraNoteFields();
      }
    })
  },

  showExtraNoteFields: ()=>{
    $('.note_matter, #note-attach-button, #follow_up_task, .form-actions, #note-attachments').slideDown('slow')
    $('.short-note-wrapper').find('trix-editor').css('min-height','10em');
    $('#new_note').addClass('note-extended');
  },

  hideExtraNoteFields: ()=>{
    $('.note_matter, #note-attach-button, #follow_up_task, .form-actions, #note-attachments').slideUp('slow')
    $('.short-note-wrapper').find('trix-editor').removeAttr('style')
    $('#new_note').removeClass('note-extended');
  },

  displayTaskForm: ()=>{
    $(document).on('keyup', '#task_title', ()=>{
      if ($('#task_title').val().length > 0){
        Dashboard.showExtraTaskFields()
      } else {
        Dashboard.hideExtraTaskFields()
      }
    })
  },

  showExtraTaskFields: () =>{
    $('#task-extra-fields').slideDown('slow')
  },

  hideExtraTaskFields: ()=>{
    $('#task-extra-fields').slideUp('slow')
  },

  updateNav: (type, dbhNav)=>{
    const existingNav = $(`#${type}`).find('.active_nav')
    const newNav = dbhNav
    existingNav.removeClass('active_nav')
    newNav.addClass('active_nav')
    if (type == 'dbh-nav'){
      Dashboard.displayRelatedSubNav(dbhNav)
    }
  },

  displayRelatedSubNav: (dbhNav)=>{
    const existingSubNav = $('#dbh-subnav').find('.active-subnav')
    const newNav = $('#dbh-subnav').find(`#${dbhNav.data('id')}-subnav`)
    existingSubNav.removeClass('active-subnav')
    newNav.addClass('active-subnav')
    newNav.find('ul>li.active_nav').removeClass('active_nav')
    newNav.find('ul').children().first().addClass('active_nav')
  },

  hasDefaultContent: (dbhPane) => {
    return dbhPane.children().first().hasClass('dbh-default-content')
  },

  loadContent: (dbhNav) =>{
    const selector = Dashboard.setSelector(dbhNav)
    $.ajax({
      url: `/${selector.data('type')}/${selector.data('id')}/new`,
      dataType: 'script'
    })
  },

  setSelector: (dbhNav)=>{
    if (dbhNav.hasClass('dbh-subnav-item')) {
      return dbhNav
    } else {
      const selector = $('#dbh-subnav').find(`#${dbhNav.data('id')}-subnav`).find('ul').children().first()
      return selector
    }
  },

  displayContent: (dbhNav)=>{
    const existingContent = $('.dbh-active-content')
    const dbhContent = $(`#dbh-${dbhNav.data('id')}`)
    existingContent.removeClass('dbh-active-content')
    dbhContent.addClass('dbh-active-content')
    if (Dashboard.hasDefaultContent(dbhContent)) {
      Dashboard.loadContent(dbhNav)
    } else {
      return
    }
  },

  displayHours: (content)=>{
    const existingContent = $('.dbh-active-content')
    const dbhContent = $("#dbh-hours")
    existingContent.removeClass('dbh-active-content')
    dbhContent.addClass('dbh-active-content')
    dbhContent.html(content)
  },

  displayNotes: ()=>{
    Form.reset('#new_hour')
    $('#note_attachments').empty()
    $('#dbh-hours').removeClass('dbh-active-content')
    $('#dbh-notes').addClass('dbh-active-content')
  },
  actionNavigation: ()=>{
    $(document).on('click', '.dbh-nav', (e)=>{
      const dbhNav = $(e.currentTarget)
      let type
      if (dbhNav.parent().parent().attr('id') == 'dbh-nav'){
        type = 'dbh-nav'
      } else {
        type = 'dbh-subnav'
      }
      Dashboard.updateNav(type, dbhNav)
      Dashboard.displayContent(dbhNav)
    })
  }
}
Dashboard.actionNavigation()
Dashboard.displayNoteForm()
Dashboard.displayTaskForm()
