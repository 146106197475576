Document = {
  TASK: false,
  NOTE: false,
  VERIFICATION: new Array,
  ACCOUNT: new Array,
  ATTACHMENT: new Array

}

DirectUploader = {

  removeUpload: ()=>{
    $(document).on('click','.remove-attachment', function(e){
      e.preventDefault()
      const $element = $(e.currentTarget)
      const file = $($element.data('id')).val()
      $.ajax({
        url: '/delete_attachment',
        dataType: 'script',
        method: 'DELETE',
        data: { key: file },
        success: ()=>{
          $element.parents('.attachment').fadeOut(300, function(){
            this.remove()
          })
        }
      })
    })
  },
  bytesToSize: function(bytes,decimals=2){
    if (bytes == 0){
      return '0 Byte'
    }
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    const k = 1024
    const dm = decimals <= 0 ? 0 : decimals
    const i = Math.floor(Math.log(bytes)/Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}` 
  },

  createAttachment: function(element,file, location){
    const selector = $(element).data('id')
    console.log(selector)
    $(`#add_${selector}_attachment`).trigger('click')
    const fileObject = $(`#${selector}_attachments`).children().last();
    console.log(fileObject)
    const locationId = fileObject.find('input').first().attr('id').replace(/\D/g,'');
    fileObject.attr('data-location', locationId)
    file.locationId = locationId
    fileObject.find(`#${selector}_documents_attributes_${locationId}_file_size`).val(file.size)
    fileObject.find(`#${selector}_documents_attributes_${locationId}_file`).val(location)
    fileObject.find(`#${selector}_documents_attributes_${locationId}_filename`).val(file.name)
    fileObject.find(`#${selector}_documents_attributes_${locationId}_content_type`).val(file.type)
    fileObject.find('.file-name').text(file.name)
    fileObject.find('.file-size').text(DirectUploader.bytesToSize(file.size))
    fileObject.find('.file-type').html("<i class='far fa-file'></i>")
  },

  createDocument: (element,file, key) =>{
    const $document =  $(DirectUploader.documentTemplate())
    $document.find('.file-name').text(file.name)
    $document.find('.file-size').text(DirectUploader.bytesToSize(file.size))
    $document.find('.file-type').html("<i class='far fa-file'></i>")
    $document.find('.remove-attachment').data('id',`${$(element).data('id')}`)
    $(element).append($document)
    $($(element).data('id')).val(key)
  },

  documentTemplate: ()=>{
    return `<div class='attachment'><div class='file-icon'></div><p class='file-name'></p><p class='file-details'><strong class='file-size'></strong> &bull; <a href='#' class='remove-attachment'>Remove</a></a></p></div>`
  },


  deleteAttachment: function(key){
    $.ajax({
      url: '/delete_attachment',
      method: 'DELETE',
      data: {key: key}
    })
  },

  setupTemplate: function(){
    if (DirectUploader.DOCUMENT_TEMPLATE == undefined){
      const previewNode = document.querySelector('#template-preview')
      previewNode.id = ''
      const previewTemplate = previewNode.parentNode.innerHTML
      previewNode.parentNode.removeChild(previewNode)
      DirectUploader.DOCUMENT_TEMPLATE = previewTemplate
    } else {
      $('#template-preview').remove()
    }
  },

  setupUpload: function(){
    if ($('.note-attachment-upload').length > 0){
      DirectUploader.noteAttachmentUpload() 
    }
    if ($('.task-attachment-upload').length > 0){
      DirectUploader.taskAttachmentUpload() 
    } 
  },

  destroyUploaders: function(){
    if (Document.TASK == true){
      Dropzone.forElement('.task-attachment-upload').destroy()
      Document.TASK = false
    }
    if (Document.NOTE == true){
      Dropzone.forElement('.note-attachment-upload').destroy()
      Document.NOTE = false
    }
  },

  attachmentUpload: (element) =>{
    $(element).dropzone({
      url: $(element).data('url'),
      previewsContainer: `#${$(element).data('id')}_preview_container`,
      previewTemplate: DirectUploader.DOCUMENT_TEMPLATE,
      clickable: `#${$(element).data('id')}_upload_button`,
      timeout: 60000,
      parrellelUploads: 1,
      uploadMultiple: false,
      init: ()=>{
        Document.ATTACHMENT.push(element)
      },
      accept: function(file, done){
        $.ajax({
          url: '/ppost',
          data: { filename: file.name, type: file.type, size: file.size },
          type: 'POST',
          dataType: 'json',
          success: function(data){
            file.postData = data.fields
            done()
          },
          error: function(data){
            console.log(data)
            if (data.message){
              done(data.message)
            } else {
              done('error preparing the upload')
            }
          }
        })
      },
      sending: function(file, xhr, formData){
        if ($(file.previewTemplate).find('[data-retry]').length > 0){
          $(file.previewTemplate).find('[data-retry]').fadeOut('slow') 
        }
        $.each(file.postData, function(f,v){
          formData.append(f,v)
        });
      },
      uploadprogress: function(file, progress, bytesSent){
        $(file.previewTemplate).find('.gs-progress-bar').css('width', `${progress}%`)
      },
      success: function(file, request){
        const key = $(request).find("Key").text()
        const clientType = $(element).data('client')
        $(file.previewTemplate).fadeOut('slow')
        DirectUploader.createAttachment(element, file, key)
      },
      error: function(file, message,xhr){
        $(file.previewTemplate).find('[data-retry]').show('slow');
        $(file.previewTemplate).find('.gs-progress-bar').addClass('gs-progress-error')
        $(file.previewTemplate).find('.error').text("We experienced an error trying to receive your file, please try again.")
      }
    })
  },

  accountsDocumentUpload: (element) =>{
    $(element).dropzone({
      url: $(element).data('url'),
      previewsContainer: `${$(element).data('id')}_preview`,
      previewTemplate: DirectUploader.DOCUMENT_TEMPLATE,
      clickable: `${$(element).data('id')}_upload`,
      timeout: 60000,
      parrellelUploads: 1,
      uploadMultiple: false,
      init: ()=>{
        Document.ACCOUNT.push(element)
      },
      accept: function(file, done){
        $.ajax({
          url: '/ppost',
          data: { filename: file.name, type: file.type, size: file.size },
          type: 'POST',
          dataType: 'json',
          success: function(data){
            file.postData = data.fields
            done()
          },
          error: function(data){
            console.log(data)
            if (data.message){
              done(data.message)
            } else {
              done('error preparing the upload')
            }
          }
        })
      },
      sending: function(file, xhr, formData){
        if ($(file.previewTemplate).find('[data-retry]').length > 0){
          $(file.previewTemplate).find('[data-retry]').fadeOut('slow') 
        }
        $.each(file.postData, function(f,v){
          formData.append(f,v)
        });
      },
      uploadprogress: function(file, progress, bytesSent){
        $(file.previewTemplate).find('.gs-progress-bar').css('width', `${progress}%`)
      },
      success: function(file, request){
        const key = $(request).find("Key").text()
        const clientType = $(element).data('client')
        $(file.previewTemplate).fadeOut('slow')
        DirectUploader.createDocument(element, file, key)
      },
      error: function(file, message,xhr){
        $(file.previewTemplate).find('[data-retry]').show('slow');
        $(file.previewTemplate).find('.gs-progress-bar').addClass('gs-progress-error')
        $(file.previewTemplate).find('.error').text("We experienced an error trying to receive your file, please try again.")
      }
    })
  },

  verificationUpload: (element)=>{
    $(element).dropzone({
      url: $('#client-form').data('url'),
      previewsContainer: `${$(element).data('id')}_preview`,
      previewTemplate: DirectUploader.DOCUMENT_TEMPLATE,
      clickable: `${$(element).data('id')}_upload`,
      timeout: 60000,
      parrellelUploads: 1,
      uploadMultiple: false,
      init: ()=>{
        Document.VERIFICATION.push(element)
      },
      accept: function(file, done){
        $.ajax({
          url: '/ppost',
          data: { filename: file.name, type: file.type, size: file.size },
          type: 'POST',
          dataType: 'json',
          success: function(data){
            file.postData = data.fields
            done()
          },
          error: function(data){
            console.log(data)
            if (data.message){
              done(data.message)
            } else {
              done('error preparing the upload')
            }
          }
        })
      },
      sending: function(file, xhr, formData){
        if ($(file.previewTemplate).find('[data-retry]').length > 0){
          $(file.previewTemplate).find('[data-retry]').fadeOut('slow')
        }
        $.each(file.postData, function(f,v){
          formData.append(f,v)
        });
      },
      uploadprogress: function(file, progress, bytesSent){
        $(file.previewTemplate).find('.gs-progress-bar').css('width', `${progress}%`)
      },
      success: function(file, request){
        const key = $(request).find("Key").text()
        const clientType = $(element).data('client')
        $(file.previewTemplate).fadeOut('slow')
        DirectUploader.createDocument(element, file, key)
      },
      error: function(file, message,xhr){
        $(file.previewTemplate).find('[data-retry]').show('slow');
        $(file.previewTemplate).find('.gs-progress-bar').addClass('gs-progress-error')
        $(file.previewTemplate).find('.error').text("We experienced an error trying to receive your file, please try again.")
      }
    })
  },
}

$(document).on('click','#note-attach-button',function(e){
  e.preventDefault()
});

$(document).on('click','#task-attach-button',function(e){
  e.preventDefault()
});

document.addEventListener('trix-file-accept', function(){
  event.preventDefault()
});

$(document).on('turbolinks:load', function(){
  DirectUploader.removeUpload()
  DirectUploader.setupTemplate()
  $('.verification-upload').each((index, element)=>{
    DirectUploader.verificationUpload(element)
  })
  $('.attachment-upload').each((index, element)=>{
    DirectUploader.attachmentUpload(element)
  })
});

$(document).on('turbolinks:before-cache', function(){
  DirectUploader.destroyUploaders()
});

$(document).on('cocoon:before-remove','#note-attachments',function(e,attachment){
  DirectUploader.deleteAttachment(attachment.find('.note_documents_file > input').val())
});

$(document).on('cocoon:before-remove','#task-attachments',function(e,attachment){
  DirectUploader.deleteAttachment(attachment.find('.task_documents_file > input').val())
});