Initializers = {
  flatpickrInstances: new Array,
  select2Instances: new Array,

  setupFlatPickr: ()=>{
    let $datetimeSelectors = Array.from(document.querySelectorAll('.datetimepicker'))
    let $datepickerSelectors = Array.from(document.querySelectorAll('.datepicker'))
    let $timepickersSelectors = Array.from(document.querySelectorAll('.timepicker'))
    if ($datetimeSelectors.length > 0){
      $datetimeSelectors.forEach((element)=>{
        let fp = flatpickr(element, {
          wrap: true,
          enableTime: true,
          dateFormat: "Y-m-d H:i",
          altInput: true,
          altFormat: "F j, Y, H:i"
        });
        Initializers.flatpickrInstances.push(fp)
      });
    }
    if ($datepickerSelectors.length > 0){
      $datepickerSelectors.forEach( (element) =>{
        let flatpickrInstance = flatpickr(element, {
          wrap: true,
          enableTime: false,
          dateFormat: "Y-m-d",
          altInput: true,
          altFormat: "F j, Y"
        })
        Initializers.flatpickrInstances.push(flatpickrInstance)
      });
    }
    if ($timepickersSelectors.length > 0){
      $timepickersSelectors.forEach( (element) =>{
        let flatpickrInstance = flatpickr(element, {
          wrap: true,
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i"
        })
        Initializers.flatpickrInstances.push(flatpickrInstance)
      });
    }
  },
  deleteFlatpickr: ()=>{
    if (Initializers.flatpickrInstances.length > 0) {
      Initializers.flatpickrInstances.forEach((element)=>{
        element.destroy()
      })
    }
  },
  setupSelect2: ()=>{
    if ($('.chosen-select').length > 0){
      $('.chosen-select').select2({
        placeholder: $('.chosen-select').data('placeholder'),
        width: '100%'
      });
    }
    if ($('.modal-select').length > 0 ){
      $('.modal-select').select2({
        placeholder: $('.modal-select').data('placeholder'),
        width: '100%',
        dropdownParent: '.modal-body'
      })
    }
  }
}

document.addEventListener('turbolinks:load', ()=>{
  $(document).on('cocoon:after-insert', '.nested-field', ()=>{
    Initializers.setupFlatPickr()
  })
  Initializers.setupFlatPickr()
  $('.chosen-select').select2({
    placeholder: $('.chosen-select').data('placeholder'),
    width: '100%'
  });

  if ($('#new_event').length > 0){
    Events.eventsForm('event');
  }
  if ($('#new_court_date').length > 0){
    Events.eventsForm('court_date')
  }
  if ($('#new_meeting').length > 0){
    Events.eventsForm('meeting')
  }
  if ($('#new_consultation').length > 0){
    Events.eventsForm('consultation')
  }

  if ($('.edit_event').length > 0){
    Events.editEventsForm('event');
  }
  if ($('.edit_court_date').length > 0){
    Events.editEventsForm('court_date')
  }
  if ($('.edit_meeting').length > 0){
    Events.editEventsForm('meeting')
  }
  if ($('.edit_consultation').length > 0){
    Events.editEventsForm('consultation')
  }
  if ($('.payor-select').length > 0){
    $('.payor-select').prepend(new Option('Add New Payor', 'new-payor', false, false))
  }
  if ($('.payee-select').length > 0){
    $('.payee-select').prepend(new Option('Add New Payee', 'new-payee', false, false))
  }
});


document.addEventListener('turbolinks:before-cache', ()=>{
  Initializers.deleteFlatpickr()
});
