Quotations = {

  addPayor:()=>{
    $(document).on('select2:select', '#quotation_payor.payor-select', function(e){
      if (e.params.data.id == 'new-payor'){
        $.ajax({
          url: '/payors/new',
          dataType: 'script',
        })
      } else {
        $.ajax({
          url: `/quotations/${$('#quotation_payor').data('quotation')}/payor`,
          dataType: 'script',
          method: 'PATCH',
          data: {payor_id: e.params.data.id}
        })
      }
    })
  },
  updateQuotationOptions:()=>{
    $(document).on('change', '#accounts_quotation_date, #accounts_quotation_expiry_date, #accounts_quotation_number, #accounts_quotation_subject, #accounts_quotation_currency, #accounts_quotation_country', (e)=>{
      const quotationForm = $(e.currentTarget).parents('form')[0]
      Rails.fire(quotationForm, 'submit')
    });
  },
  updateQuotationAddress:()=>{
    $(document).on('click', '#existing-quotation-addresses > .possible-address', (e)=>{
      const addressId = $(e.currentTarget).data('id')
      const salesId = $(e.currentTarget).data('quotation')
      $.ajax({
        url: `/quotations/${salesId}`,
        method: 'PATCH',
        dataType: 'script',
        data: { accounts_quotation: {address_id: addressId }, id: salesId }
      })
    })
  }
}

Quotations.addPayor()
Quotations.updateQuotationOptions()
Quotations.updateQuotationAddress()
