Events = {

  index: (date, user) => {
    $.get(`/event_scheduler?date=${date}&user_id=${user}`)
  },

  typeSelect: () => {
    $(document).on('select2:select','#event_type', function(e){
      console.log(e)
      let type = e.params.data.id
      let matter_id = $(e.currentTarget).data('matter')
      if (type == "CourtDate"){

        Events.reloadForm("CourtDate",matter_id);
      
      } else if (type == "Meeting") {

        Events.reloadForm("Meeting",matter_id)

      } else {

      }
    })
  },

  reloadForm: (type,matter) => {
    $.get("/reload_events_form", {type: type, matter_id: matter})
  },

  userSelect: ()=>{
    $(document).on('change','#court_date_new_user_ids, #meeting_new_user_ids, #event_new_user_ids', function (e, data){

        const user = data.selected;
        const eventType = $(e.currentTarget).attr('id').split('_new_user_ids').join('');
        const value = $(`#${eventType}_date`).val();
        const date = moment(value, "DD-MM-YYYY").format("YYYY-MM-DD");
        Events.index(date, user);
      });
  },

  checkTime: (selector) =>{
    console.log($(`#${selector}_start_time`).val())
    if ($(`#${selector}_start_time`).val() == ""){
      return moment("2020-01-01 09:00", "YYYY-MM-DD HH:mm")
    } else{
      return moment($(`#${selector}_start_time`).val(),"YYYY-MM-DD HH:mm")
    }
  },

  eventsForm: (selector, target, html) =>{
    if (typeof html != null && typeof target != null){
      $(target).html(html);
    }
    $('.chosen-select').select2({
      placeholder: $('.chosen-select').data('placeholder'),
      width: '100%'
    });
    const startsAtSelector = $(`.${selector}_start_time`).find('.datetimepicker');
    const endsAtSelector = $(`.${selector}_end_time`).find('.datetimepicker');
    const endsAt = endsAtSelector.flatpickr({
      wrap: true,
      enableTime: true,
      noCalendar: true,
      altInput: true,
      altFormat: "h:i K",
      dateFormat: "Y-m-d H:i"
    });
    const startsAt = startsAtSelector.flatpickr({
      wrap: true,
      enableTime: true,
      noCalendar: true,
      altInput: true,
      altFormat: "h:i K",
      dateFormat: "Y-m-d H:i",
      defaultHour: moment().format("H"),
      defaultMinute: moment().format("m"),
      onChange: function(selectedDates, dateStr, instance){
        const currentHour = moment(selectedDates[0])
        const computedHour = currentHour.add('1','hour')
        endsAt.setDate(computedHour.toDate())
      }
    });
    flatpickr('.datepicker',{
      wrap: true,
      dateFormat: "Y-m-d",
      altInput: true,
      altFormat: "F j, Y",
      minDate: 'today',
      onChange: (selectedDates, dateStr, instance) =>{
        const currentDate = moment(selectedDates[0])
        const currentHour = Events.checkTime(selector)
        console.log(currentHour)
        const computedString = `${currentDate.format("YYYY-MM-DD")} ${currentHour.format("HH:mm")}`
        console.log(computedString)
        const computedTime = moment(computedString, 'YYYY-MM-DD H:mm')
        startsAt.setDate(computedTime.toDate())
        endsAt.setDate(computedTime.add('1','hour').toDate())
      }
    });

    $(`#${selector}_all_day`).click, function(){
      if (this.checked){
        $(`#${selector}_starts_at`).val('');
        $(`#${selector}_ends_at`).val('');
        $(`${selector}_starts_at`).prop('disabled', true);
        $(`#${selector}_ends_at`).prop('disabled', true);
      } else {
        $(`#${selector}_starts_at`).prop('disabled', false);
        $(`#${selector}_ends_at`).prop('disabled',false);
      }
    }
    if (selector == 'event'){
      Events.typeSelect()
    }
  },

  editEventsForm: (selector, target, html)=> {
    if (typeof html != null && typeof target != null){
      $(target).html(html);
    }
    $('.chosen-select').select2({
      placeholder: $('.chosen-select').data('placeholder'),
      width: '100%'
    });
    const startsAtSelector = $(`.${selector}_start_time`).find('.datetimepicker');
    const endsAtSelector = $(`.${selector}_end_time`).find('.datetimepicker');
    console.log(startsAtSelector)
    console.log(endsAtSelector)
    const startsAtTime = moment($(`#${selector}_start_time`).val())
    const endsAtTime = moment($(`#${selector}_end_time`).val())
    const endsAt = endsAtSelector.flatpickr({
      wrap: true,
      enableTime: true,
      noCalendar: true,
      altInput: true,
      altFormat: "h:i K",
      dateFormat: "Y-m-d H:i"
    });
    const startsAt = startsAtSelector.flatpickr({
      wrap: true,
      enableTime: true,
      noCalendar: true,
      altInput: true,
      altFormat: "h:i K",
      dateFormat: "Z",
      onChange: function(selectedDates, dateStr, instance){
        const currentHour = moment(selectedDates[0])
        const computedHour = currentHour.add('1','hour')
        endsAt.setDate(computedHour.toDate())
      }
    });
    flatpickr('.datepicker',{
      wrap: true,
      dateFormat: "Y-m-d",
      altInput: true,
      altFormat: "F j, Y",
      minDate: 'today',
      onChange: (selectedDates, dateStr, instance) =>{
        const currentDate = moment(selectedDates[0])
        const currentHour = Events.checkTime(selector)
        console.log(currentHour)
        const computedString = `${currentDate.format("YYYY-MM-DD")} ${currentHour.format("HH:mm")}`
        console.log(computedString)
        const computedTime = moment(computedString, 'YYYY-MM-DD H:mm')
        startsAt.setDate(computedTime.toDate())
        endsAt.setDate(computedTime.add('1','hour').toDate())
      }
    });

    $(`#${selector}_all_day`).click, function(){
      if (this.checked){
        $(`#${selector}_starts_at`).val('');
        $(`#${selector}_ends_at`).val('');
        $(`${selector}_starts_at`).prop('disabled', true);
        $(`#${selector}_ends_at`).prop('disabled', true);
      } else {
        $(`#${selector}_starts_at`).prop('disabled', false);
        $(`#${selector}_ends_at`).prop('disabled',false);
      }
    }
  }
}
