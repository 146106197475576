SalesReceipts = {

  addPayor:()=>{
    $(document).on('select2:select', '#sales_receipt_payor.payor-select', function(e){
      if (e.params.data.id == 'new-payor'){
        $.ajax({
          url: '/payors/new',
          dataType: 'script',
        })
      } else {
        $.ajax({
          url: `/sales_receipts/${$('#sales_receipt_payor').data('sales-receipt')}/payor`,
          dataType: 'script',
          method: 'PATCH',
          data: {payor_id: e.params.data.id}
        })
      }
    })
  }
}

SalesReceipts.addPayor()
