Invoice = {

  displayFinalAmount: (type)=>{
    $(document).on('change keyup', `#accounts_${type}_quantity, #accounts_${type}_unit_amount`, ()=>{
      const quantity = Invoice.checkValue($(`#accounts_${type}_quantity`).val())
      const unitAmount = Invoice.checkValue($(`#accounts_${type}_unit_amount`).val())
      const finalAmount = (quantity * unitAmount).toFixed(2)
      $(`#accounts_${type}_amount`).val(`${finalAmount}`)
    })
  },
  displayRelatedBillingFee: (type)=>{
    $(document).on('change', `#accounts_${type}_user_id`, ()=>{
      const selectedUser = $(`#accounts_${type}_user_id option:selected`).text()
      const possibleUserRates = $(`#accounts_${type}_unit_amount`).data('options')
      const selectedUserRate = possibleUserRates[selectedUser]
      $(`#accounts_${type}_unit_amount`).val(selectedUserRate)
    })
  },
  checkValue: (input)=>{
    if (input.length > 0){
      return parseFloat(input)
    } else {
      return 0
    }
  },
  updateInvoiceOptions:()=>{
    $(document).on('change', '#accounts_invoice_date, #accounts_invoice_due_date, #accounts_invoice_invoice_number, #accounts_invoice_currency, #accounts_invoice_note, #accounts_invoice_country, #accounts_sales_receipt_sales_receipt_number, #accounts_sales_receipt_date, #accounts_sales_receipt_currency, #accounts_sales_receipt_country', (e)=>{
      const invoiceForm = $(e.currentTarget).parents('form')[0]
      Rails.fire(invoiceForm, 'submit')
    });
  },
  updateInvoiceAddress:()=>{
    $(document).on('click', '#existing-invoice-addresses > .possible-address', (e)=>{
      const addressId = $(e.currentTarget).data('id')
      const invoiceId = $(e.currentTarget).data('invoice')
      $.ajax({
        url: `/invoices/${invoiceId}`,
        method: 'PATCH',
        dataType: 'script',
        data: { accounts_invoice: {address_id: addressId }, id: invoiceId }
      })
    })
  },

  updateSalesReceiptAddress:()=>{
    $(document).on('click', '#existing-sales-receipt-addresses > .possible-address', (e)=>{
      const addressId = $(e.currentTarget).data('id')
      const salesId = $(e.currentTarget).data('sales-receipt')
      $.ajax({
        url: `/sales_receipts/${salesId}`,
        method: 'PATCH',
        dataType: 'script',
        data: { accounts_sales_receipt: {address_id: addressId }, id: salesId }
      })
    })
  }
}

Invoice.displayFinalAmount("entry")
Invoice.displayFinalAmount("fee")
Invoice.displayFinalAmount("reimbursable_expense")
Invoice.displayRelatedBillingFee('entry')
Invoice.displayRelatedBillingFee('fee')
Invoice.updateInvoiceOptions()
Invoice.updateInvoiceAddress()
Invoice.updateSalesReceiptAddress()
