Client = {
  autocompleteClients: function(){
    $(document).on('keyup', '#client_query', function(){
      $.ajax({
        url: '/clients',
        dataType: 'script',
        data: {q: `${$('#client_query').val()}`}
      })
    })
  },

  existingClient: function(){
    $(document).on('keyup', '.client_name', function(e){
      let name = $(e.currentTarget).val()
      let matter = $(e.currentTarget).data('matter')
      $.ajax({
        url: '/clients/similar_names',
        dataType: 'script',
        data: { q: name, matter: matter },
        method: 'GET'
      })
    })
  }
}
Client.autocompleteClients()
Client.existingClient()
