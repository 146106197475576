class Timer
  @all: []

  @count: 0

  @addCount: ->
    Timer.count = Timer.count + 1

  @find: (key) ->
    timer for timer in @all when timer.id is key

  @inactiveTimers: ->
    timers = $('[data-active="false"]')
    new Timer (timer) for timer in timers
    TimerMenu.show() unless TimerMenu.open()

  @pauseExistingTimers: (currentTimer) ->
    if currentTimer == undefined
      timer.pause() for timer in @all
    else
      timer.pause() for timer in @all when timer isnt currentTimer

  @updateTimerNumber: ->
    count =  @all.length
    if count == 0
      $('.timers_number').remove()
      no_content = $("<div class='component-card-item no_content' id='no-timers-content'><i class='fa fa-info-circle'></i><br> You have no timers.<br> </div>")
      $('#timers-list').append(no_content)
    else
      $('.timers_number').text(count)

  @getCurrentTimer: (timer_object) ->
    key = parseInt(timer_object.attr('id').split('timer-').join(''), 10)
    results = Timer.find(key)
    currentTimer = results[0]
    currentTimer

  constructor: (timer) ->
    Timer.addCount()
    if timer == undefined
      @label = "Timer #{Timer.count}"
      @startTime = moment()
      @stopTime = ''
      @id = @generateId()
      @durations = [0]
      @timer = 0
      @paused = false
      Timer.pauseExistingTimers()
      Timer.all.push @
      @createTimer()
    else
      $timer = $(timer)
      @startTime = moment($timer.data('time'))
      @id = parseInt($timer.attr('id').split('timer-').join(''), 10)
      @durations = if $timer.data('durations') == 0
        [0]
      else
        $timer.data('durations').split(',').map(Number)
      @label = $timer.find('.timer-label').text()
      @paused = $timer.data('paused')
      @timer = 0
      Timer.all.push this
      @createExistingTimer()

  generateId: (length = 8) ->
    id = ''
    id = Math.random().toString(36).substr(2)

  timerListing: ->
     $("#timer-#{@id}")

  createExistingTimer: ->
    listing = @timerListing()
    listing.attr('data-active', true)
    @record() if @paused == false

  createTimer: ->
    @addToTimersList()
    @addToServer()
    @record()
    TimerMenu.show() unless TimerMenu.open()

  addToTimersList: ->
    listing = $(@timerBody())
    count = Timer.all.length
    listing.attr('id', "timer-#{@id}")
    $('#timers-list').prepend(listing)
    if $('.timers_number').length > 0
      $('.timers_number').text(count)
    else
      $('#no-timers-content').remove()
      timersNumber = $("<span class='timers_number'>#{count}</span>")
      $('#timers_holder').append(timersNumber)

  updateLabel: (text) ->
    @label = text

  timerBody: ->
    duration = @formattedTime()
    body = "
      <div class='component-card-item timer'>
        <div class='timer-header row'>
          <div class='col-9'>
            <div class='timer-label' contenteditable=true>#{@label}</div>
          </div>
          <div class='col-3'>
            <i class='fas fa-pencil label-edit'></i>
          </div>
        </div>
        <div class='timer-display'><span class='time'>#{duration}</span></div>
        <div class='timer-controls container-fluid'>
          <div class='row'>
            <div class='col-4'>
              <i class='fas fa-pause timer-pause'></i><br>
              Pause
            </div>
            <div class='col-4'>
              <i class='fas fa-save timer-save'></i><br>
              Finish
            </div>
            <div class='col-4'>
              <i class='fas fa-times timer-remove'></i><br>
              Delete
            </div>
          </div>
        </div>
      </div>"

  addToServer: ->
    variable = this
    $.ajax
      url: '/timers',
      dataType: 'json',
      type: 'POST',
      data:
        timer:
          label: @label,
          start_time: @startTime.format(),
          key: @id
      success: (data) ->
        variable.timerListing().attr('id', "timer-#{data.id}")
        variable.id = data.id

  currentDuration: ->
    moment() - @startTime

  totalDuration: ->
    @durations.reduce (t,s) -> t + s

  duration: ->
    moment.duration(@currentDuration() + @totalDuration())

  hours: ->
    value = @duration().get('hours')
    value = @pad(value)
    return value

  minutes: ->
    value = @duration().get('minutes')
    value = @pad(value)
    return value

  seconds: ->
    value = @duration().get('seconds')
    value = @pad(value)
    return value

  record: ->
    timing = this
    @timer = setInterval () ->
      timing.displayTime()
    ,1000

  formattedTime: ->
    hours = @hours()
    minutes = @minutes()
    seconds = @seconds()
    time = "#{hours}:#{minutes}:#{seconds}"
    return time

  displayTime: ->
    time = @formattedTime()
    $("#timer-#{@id}").find('.time').text("#{time}")

  updateOnServer: ->
    variable = this
    $.ajax
      url: "/timers/#{@id}",
      dataType: 'json',
      type: 'PATCH',
      data:
        timer:
          label: variable.label,
          start_time: variable.startTime.format(),
          durations: variable.durations,
          paused: variable.paused

  updateTimerDisplay: (action) ->
    timer = @timerListing()
    if action == 'pause'
      timer.find('.fa-pause').addClass('fa-play timer-resume').removeClass('fa-pause timer-pause')
    else
      timer.find('.fa-play').addClass('fa-pause timer-pause').removeClass('fa-play timer-resume')

  pause: ->
    unless @timer == 0
      clearInterval(@timer)
      @timer = 0
      @paused = true
      @durations.push @currentDuration()
      @updateTimerDisplay('pause')
      @updateOnServer()

  resume: ->
    if @timer == 0
      @startTime = moment()
      @paused = false
      Timer.pauseExistingTimers(this)
      @updateTimerDisplay('resume')
      @updateOnServer()
      @record()

  destroy: ->
    @removeFromTimersList()
    @deleteFromServer()


  removeFromTimersList: ->
    timer_object = @timerListing()
    timer_object.slideUp 'slow', ->
      this.remove()

  deleteFromServer: ->
    variable = this
    $.ajax
      url: "/timers/#{variable.id}",
      dataType: 'json',
      type: 'DELETE'
      success: (data) ->
        Timer.all.pop variable
        Timer.updateTimerNumber()

  save: ->
    @pause()
    $.get "/timers/#{@id}/hours/new"

  pad: (value) ->
    if value < 10
      return "0#{value}"
    else
      "#{value}"

TimerForm =

  tasks: ->
    $(document).on 'change', '#hour_matter_id', (e) ->
      matterId = $(e.currentTarget).val()
      $.ajax
        url: "/matters/#{matterId}/tasks",
        dataType: 'json',
        type: 'GET'
        success: (data) ->
          TimerForm.clearOptions()
          if data.length > 0
            TimerForm.createOption(task) for task in data
            $('#timer_tasks_holder').show()
            $('#timer_notes_holder').fadeOut()
          else
            $('#timer_tasks_holder').fadeOut()
            $('#timer_notes_holder').show()

  clearOptions: ->
      results = $('.hour_task').find('.radio')
      option.remove() for option in results

  createOption: (task) ->
    option = "<span class='radio'>
      <label for='hour_task_#{task.id}'>
        <input class='radio_buttons optional' type='radio' value='#{task.id}' name='hour[task]' id='hour_task_#{task.id}'>
        #{task.title}
      </label>
    </span>"
    $('.hour_task').append($(option))

  didNotSelectTasks: ->
    $(document).on 'click', '#no_tasks_select', (e) ->
      e.preventDefault()
      $('#timer_tasks_holder').fadeOut()
      $('#timer_notes_holder').show()

TimerMenu =
  show: ->
    $('#timers_droplist').slideDown('slow')
    $('.timers_dropdown').removeClass('timers-hidden').addClass('timers-shown')

  hide: ->
    $('#timers_droplist').slideUp('slow')
    $('.timers_dropdown').removeClass('timers-shown').addClass('timers-hidden')

  open: ->
    if $('.timers-shown').length > 0
      true
    else
      false


TimerForm.tasks()
TimerForm.didNotSelectTasks()

$(document).on 'click', '#timers_holder', ->
  if $('.timers_dropdown').hasClass('timers-hidden')
    TimerMenu.show()
  else
    TimerMenu.hide()

$(document).on 'click', ->
  if $('.timers_dropdown').hasClass('timers-shown')
    TimerMenu.hide()

$(document).on 'click','.timers_dropdown', (e) ->
  e.stopPropagation()

$(document).on 'click', '.record-time', ->
  new Timer

$(document).on 'click', '.timer-pause', (e) ->
  timer_object = $(e.currentTarget).closest('.timer')
  currentTimer = Timer.getCurrentTimer(timer_object)
  currentTimer.pause()

$(document).on 'click', '.timer-resume', (e) ->
  timer_object = $(e.currentTarget).closest('.timer')
  currentTimer = Timer.getCurrentTimer(timer_object)
  currentTimer.resume()

$(document).on 'click', '.timer-save', (e) ->
  timer_object = $(e.currentTarget).closest('.timer')
  currentTimer = Timer.getCurrentTimer(timer_object)
  currentTimer.save()

$(document).on 'click', '.timer-remove', (e) ->
  timer_object = $(e.currentTarget).closest('.timer')
  currentTimer = Timer.getCurrentTimer(timer_object)
  currentTimer.destroy()

$(document).on 'click', '.label-edit', (e) ->
  console.log(e)
  label = $(e.currentTarget).closest('.timer-header').find('.timer-label')
  console.log(label)
  label.attr('contenteditable', true)
  label.addClass('edit-timer-label')
  $(e.currentTarget).addClass('fa-check complete-edit').removeClass('fa-pencil label-edit')

$(document).on 'click', '.complete-edit', (e) ->
  label = $(e.currentTarget).closest('.timer-header').find('.timer-label')
  label.attr('contenteditable', false)
  label.removeClass('edit-timer-label')
  $(e.currentTarget).removeClass('fa-check complete-edit').addClass('fa-pencil label-edit')
  timer_object = $(e.currentTarget).closest('.timer')
  currentTimer = Timer.getCurrentTimer(timer_object)
  currentTimer.updateOnServer()

$(document)
  .on 'focus', '.timer-label', (e) ->
    variable = $(e.currentTarget)
    variable.attr('data-before', variable.text())
  .on 'blur keyup paste input', '.timer-label', (e) ->
    variable = $(e.currentTarget)
    text = variable.text()
    unless variable.attr('data-before') == text
      timer_object = variable.closest('.timer')
      currentTimer = Timer.getCurrentTimer(timer_object)
      currentTimer.updateLabel(text)

$(document).on 'turbolinks:load', ->
  if $('[data-active="false"]').children().length > 0
    Timer.inactiveTimers()
