# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/
WebNotifications =

  checkSubscription: ->
    if @areNotificationsSupported()
      navigator.serviceWorker.ready.then (serviceWorkerRegistration) ->
        serviceWorkerRegistration.pushManager.getSubscription()
        .then (subscription) ->
          if subscription
            WebNotifications.sendToServer(subscription)
          else
            WebNotifications.displayPrompt()

  displayPrompt: ->
    prompt = $("<div class='row'><div class='col-12'><div class='alert alert-info alert-dismissible' role='alert'>
      <button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'><i class='fa fa-times'></i></span></button>
      <h3>Subscribe to Notifications</h3>
      <p>
      In order to stay up to date with everything in Gestus, you should turn on browser notifications.
      </p>
      <button type='button' id='subscribe_notifications' class='btn btn-success'>Subscribe</button><button type='button' class='btn btn-danger' data-dismiss='alert'>No Thanks</button>
    </div></div></div>")
    $('.main').find('.container-fluid').prepend(prompt)

  sendToServer: (subscription) ->
    subscription = subscription.toJSON()
    deviceData =
      endpoint: subscription.endpoint
      p256dh: subscription.keys?.p256dh
      auth: subscription.keys?.auth
    $.ajax
      url: '/notifications/devices/check'
      method: 'PATCH'
      contentType: 'application/json'
      dataType: 'json'
      data: JSON.stringify(device: deviceData)
    .then($('.alert').remove())
    .fail(Logger.warn.bind window, "Error saving subscription")



  areNotificationsSupported: ->
    unless ServiceWorkerRegistration and "showNotification" of ServiceWorkerRegistration.prototype
      Logger.warn("Notifications aren't supported")
      return false

    if Notification?.permission is "denied"
      Logger.warn("notifications are blocked on this device")
      return false

    unless "PushManager" of window
      Logger.warn("Push messaging isn't supported")
      return false

    true

  processSubscription: (subscription) ->
    @enableNotificationBtn()

    if subscription?
      console.log "Existing subscription found", subscription
      @enablePush()
      @saveSubscription(subscription)

  saveSubscription: (subscription) ->
    subscription = subscription.toJSON()
    deviceData =
      endpoint: subscription.endpoint
      p256dh: subscription.keys?.p256dh
      auth: subscription.keys?.auth
    $.ajax
      url: '/notifications/devices'
      method: 'POST'
      contentType: 'application/json'
      dataType: 'json'
      data: JSON.stringify(device: deviceData)
    .then($('.alert').remove())
    .fail(Logger.warn.bind window, "Error saving subscription")

  deleteSubscription: (subscription) ->
    endpoint = subscription.toJSON().endpoint

    $.ajax
      url: "/notifications/devices/#{btoa endpoint}"
      method: 'DELETE'
      contentType: 'application/json'
      dataType: 'json'
    .then(console.log.bind console, "Subscription deleted")
    .fail(Logger.warn.bind window, "Error deleting subscription")

  enableNotificationBtn: ->
    $('#subscribe_notifications').prop('disabled', false)

  enablePush: ->
    $('#subscribe_notifications').text('Disable Notifications')
    App.isPushEnabled = true

  disablePush: (maintainState = false) ->
    $('#subscibe_notifications').text("Enable Notifications")

    unless maintainState
      App.isPushEnabled = false

  setupServiceWorker: (registration) ->
    console.log("Service worker registered", registration)

    return unless WebNotifications.areNotificationsSupported()

    registration.pushManager.getSubscription()
      .then(WebNotifications.processSubscription())
      .catch(Logger.error.bind window, "Error checking subscription")


  requestPermission: ->
    if (!('Notification') in window)
      Logger.error.bind window, "This browser does not support desktop notification"
    else if Notification.permission == "granted"
      Logger.warn("Permission to recieve notifications has been granted");
      WebNotifications.subscribe()
    else if Notification.permission != 'denied'
      Notification.requestPermission (permission) ->
        if permission == 'granted'
          WebNotifications.subscribe()


  subscribe: ->
    navigator.serviceWorker.ready.then (serviceWorkerRegistration) ->
      serviceWorkerRegistration.pushManager.subscribe(
        userVisibleOnly: true
        applicationServerKey: window.vapidPublicKey
      )
      .then (subscription) ->
        WebNotifications.enableNotificationBtn()
        WebNotifications.enablePush()

        WebNotifications.saveSubscription(subscription)

      .catch (error) ->
        if Notification?.permission is "denied"
          Logger.warn("Permission for Notifications was denied")
        else
          Logger.error("Unable to subscribe to push", error)
          WebNotifications.enableNotificationBtn()

    unsubscribe: ->
      navigator.serviceWorker.ready.then (serviceWorkerRegistration) ->
        serviceWorkerRegistration.pushManager.getSubscription()
        .then (subscription) ->
          unless subscription?
            WebNotifications.disablePush()
            WebNotifications.enableNotificationBtn()
            return

          WebNotifications.deleteSubscription(subscription)

          subscription.unsubscribe()
          .then ->
            console.log "Unsubscribed from notifications", subscription
            WebNotifications.disablePush()
            WebNotifications.enableNotificationBtn()
          .catch (error) ->
            WebNotifications.deleteSubscription(subscription)

            Logger.error("Unable to unsubscribe", error)
            WebNotifications.enableNotificationBtn()
            WebNotifications.disablePush(true)
          .catch(Logger.error.bind window, "Unable to find subscription")

App =
  isPushEnabled: false

NotificationsMenu =
  show: ->
    $('.notifications_droplist').slideDown('slow')
    $('.notifications_dropdown').removeClass('notifications-hidden')
    $('.notifications_dropdown').addClass('notifications-shown')

  hide: ->
    $('.notifications_droplist').slideUp('slow')
    $('.notifications_dropdown').removeClass('notifications-shown')
    $('.notifications_dropdown').addClass('notifications-hidden')

$(document).on 'click', '#subscribe_notifications', ->
  $('#subscribe_notifications').prop('disabled', true)

  if App.isPushEnabled then WebNotifications.unsubscribe() else WebNotifications.requestPermission()

$(document).on 'click', '#notifications_holder', ->
  if $('.notifications_dropdown').hasClass('notifications-hidden')
    NotificationsMenu.show()
  else
    NotificationsMenu.hide()

$(document).on 'click', ->
  if $('.notifications_dropdown').hasClass('notifications-shown')
    NotificationsMenu.hide()

$(document).on 'click','.notifications_dropdown', (e) ->
  e.stopPropagation()

$(document).on 'turbolinks:load', ->
    if $('#users-dashboard').length > 0
      WebNotifications.checkSubscription()
