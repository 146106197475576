Form = {
  reset: (element) =>{
    $(element).find(':input').not(':button, :submit, :reset, :hidden, :checkbox, :radio').val('')
    $(element).find(':checkbox, :radio').prop('checked', false)
    $('.chosen-select').val(null).trigger('change')

    if ($('.is-invalid').length > 0) {
      $('.is-invalid').each( (index, selector)=>{
        $(selector).removeClass('has-error')
      })
    }

    if ($('.invalid-feedback').length > 0) {
      $('.invalid-feedback').each((index, selector)=>{
        $(selector).remove()
      });
    }

    if ($(element).find('.alert-danger').length >0){
      $(element).find('.alert-danger').remove();
    }
  },

  navigation: ()=>{
    $(document).on('click', '.form-nav-item', (e)=>{
      const formNav = $(e.currentTarget)
      Form.updateNav(formNav)
      Form.displayContent(formNav)
    })
  },

  updateNav: (formNav)=>{
    const existingNav = $('#form-navigation').find('.active')
    const newNav = formNav
    existingNav.removeClass('active')
    newNav.addClass('active')
  },

  displayContent: (formNav)=>{
    const existingContent = $('.form-section.active')
    const newContent = $(`#${formNav.data('id')}`)
    existingContent.removeClass('active')
    newContent.addClass('active')
  }
}
$(document).on('turbolinks:load', ()=>{
  Form.navigation()
})