# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/
Organisation =

  followingAttorneys: ->
    following_attorneys = $('.organisation_users_following_attorneys').find('input')
    following_attorneys.on 'click', ->
      $(this).closet('.component-card-item').find('.reset_following_checkboxes').show('slow')

  resetFollowings: ->
    $('.reset_following_checkboxes').find('a').on 'click', ->
      $(this).closest('.component-card-item').find('input:checkbox').attr('checked', false).attr('disabled', false)
      $('.reset_following_checkboxes').fadeOut('slow')

  followingAll: ->
    following_all = $('.organisation_users_following_all').find('input')
    following_all.on 'click', ->
      user_group = $(this).closest('.component-card-item')
      following_attorneys = user_group.find('.organisation_users_following_attorneys').find('input')
      if this.checked
        following_attorneys.attr('checked', false).attr('disabled', true)
        user_group.find('.reset_following_checkboxes').show('slow')
      else
        following_attorneys.attr('disabled', false)

Organisation.followingAll()
Organisation.resetFollowings()
