# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/
Onboarder =

  start: ->
    if $('#new_onboarding_user').length > 0
      $.get('/start')

  hideBubble: (link) ->
    bubble = $('#currentBubble').closest('.bubble')
    bubble.fadeOut 'slow', ->
      bubble.remove()

  showBubble: (info) ->
    bubble = @createBubble info
    $('#bubble_holder').append bubble
    $('.bubble').show 'slow'
    @updateProfile(info)
    $("[data-bubble-dismiss='close']").on 'click', (e) =>
      e.preventDefault
      $(e.currentTarget).attr('id', 'currentBubble')
      @hideBubble('.bubble')

  updateProfile: (info) ->
    values = {}
    values["#{$(info).data('name')}"] = 'true'
    $.ajax({
      type: "PATCH",
      dataType: 'script',
      url: "/onboarding_profiles/#{$(info).data('user')}",
      data: { onboarding_profile: values }
      success: (data) ->
        $(info).attr('data-seen', 'true')
    })

  createBubble: (info) ->
    $bubble = $("<div style='display:none;'></div>")
    $bubble.addClass('bubble')
    $bubbleHeader = $("<div><h3>Gestus Tip</h3><i class='fa fa-info-circle'></i></div>")
    $bubbleHeader.addClass('bubble-header')
    $bubble.append($bubbleHeader)
    $bubble.append("<div class='bubble-title'> #{$(info).data('header')}</div>")
    $bubbleBody = $("<div></div>")
    $bubbleBody.addClass('bubble-body')
    $bubble.append($bubbleBody)
    $bubbleBody.html($(info).data('content'))
    $bubbleFooter = $("<div class='bubble-footer'><button class='btn btn-success btn-lg' data-bubble-dismiss='close'>Got It!</button></div>")
    $bubble.append($bubbleFooter)
    return $bubble


OnboardingWizard =

  inviteForm: ->
    $(document).on 'cocoon:before-insert', '#user_invites', (e, insertedItem) ->
      $('#user_invites_nav').find('.active').removeClass('active')
      $('#user_invites_content').find('.active').removeClass('active')
      newId = $('#user_invites_content').children().length + 1
      insertedItem.attr("id","user-#{newId}")
      insertedItem.addClass = 'active'
      insertedItem.data('nav-id', newId)

      newLink = $("<li role='presentation' class='active' data-id='user-#{newId}'>
        <a href='#user-#{newId}' aria-controls='user-#{newId}' role='tab' data-bs-toggle='tab'>User #{newId}</a>
      </li>")
      $('#user_invites_nav').append(newLink)

    $(document).on 'cocoon:before-remove', '#user_invites_content', (e, removedItem) ->
      console.log(removedItem.attr('id'))
      removedItem.prev().addClass('active')
      $('#user_invites_nav').find("[data-id='#{removedItem.attr('id')}']").prev().addClass('active')
      $('#user_invites_nav').find("[data-id='#{removedItem.attr('id')}']").remove()

    $(document).on 'keyup', '.invitee_first_name', (e) ->
      target = $(e.currentTarget)
      first_name_value = target.val()
      last_name_value = target.closest('.invitee_name').find('.invitee_last_name').val()
      first_name_value = '' if first_name_value == undefined
      last_name_value = '' if last_name_value == undefined
      userId = target.closest('.nested-fields').attr('id')
      console.log("#{userId}")
      $("[data-id=#{userId}]").find('a').text("#{first_name_value} #{last_name_value}")

    $(document).on 'keyup', '.invitee_last_name', (e) ->
      target = $(e.currentTarget)
      last_name_value =  target.val()
      first_name_value = target.closest('.invitee_name').find('.invitee_first_name').val()
      first_name_value = '' if first_name_value == undefined
      last_name_value = '' if last_name_value == undefined
      userId = target.closest('.nested-fields').attr('id')
      $("[data-id=#{userId}]").find('a').text("#{first_name_value} #{last_name_value}")

  clientSelect: ->
    $(document).on 'click', '.client_icon_holder', (e) ->
      target = $(e.currentTarget)
      $.get('/welcome/clients/new', {type: target.data('id')})






OnboardingWizard.inviteForm()
OnboardingWizard.clientSelect()
$(document).on 'click', '[data-type="bubble"]', (e) ->
  e.preventDefault
  Onboarder.showBubble(e.currentTarget)


$(document).on 'turbolinks:load', ->
  Onboarder.start()
