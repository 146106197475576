Payor = {
  checkIndividuals: ()=>{
    $(document).on('keyup', '#individual_first_name.payor_name, #individual_last_name.payor_name', function(){
      $.ajax({
        url: '/payors/individuals/existing',
        dataType: 'script',
        data: { q: `${$('#individual_first_name').val()} ${$('#individual_last_name').val()}`}
      })
    })
  },
  checkCompanies: ()=>{
    $(document).on('keyup', '#company_name.payor_name', ()=>{
      $.ajax({
        url: '/payors/companies/existing',
        dataType: 'script',
        data: { q: `${$('#company_name').val()}` }
      })
    })
  },
  checkGovernmentDepartments: ()=>{
    $(document).on('keyup', '#government_department_name.payor_name', ()=>{
      $.ajax({
        url: '/payors/government_departments/existing',
        dataType: 'script',
        data: { q: `${$('#government_department_name').val()}` }
      })
    })
  },
  addNewPayor: ()=>{
    $(document).on('select2:select', '#accounts_debt_payor_id.payor-select', function(e){
      if (e.params.data.id == 'new-payor'){
        $.ajax({
          url: '/payors/new',
          dataType: 'script',
        })
      }
    })
  }
}
Payor.checkIndividuals()
Payor.checkCompanies()
Payor.checkGovernmentDepartments()
Payor.addNewPayor()
