import { Calendar } from '@fullcalendar/core';
import momentPlugin from '@fullcalendar/moment';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';

let calendar
const eventsCalendar = function(){
  const calendarElement = document.getElementById('calendar')
  calendar = new Calendar(calendarElement,{
    plugins: [ momentPlugin, interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin ],
    themeSystem: 'bootstrap',
    selectable: true,
    header: {
      left: 'title',
      center: 'prev today next',
      right: 'dayGridMonth timeGridWeek timeGridDay'
    },
    defaultView: 'dayGridMonth',
    height: 500,
    eventLimit: 3,
    selectable: true,
    select: function(event){
      console.log(event)
      let event_attributes = {
        event: {
          date: moment(event.start).format("YYYY-MM-DD"),
          start_time: moment(event.start).format("YYYY-MM-DD HH:mm"),
          end_time: moment(event.end).format("YYYY-MM-DD HH:mm"),
          all_day: event.allDay
        }
      };
      $.ajax({
        url: "/events/new",
        data: event_attributes
      });
    },
    eventSources: [
      {
        url: '/court_dates.json',
      },
      {
        url: '/meetings.json',
        color: '#A01D26',
        textColor: '#fffff'
      },
      {
        url: '/consultations.json',
        color: '#ACBEBE',
        textColor: '#fffff'
      },
      {
        url: '/tasks.json',
        color: '#FFBD00',
        textColor: '#fffff'
      }
    ],
    eventClick: (info)=>{
      Turbolinks.visit(`/events/${info.event.id}`)
    }
  })
  calendar.render()
};

document.addEventListener('turbolinks:load', function(){
  if ($('#calendar').length > 0){
    eventsCalendar()
  } 
})

document.addEventListener('turbolinks:before-cache', function(){
  if (typeof calendar == 'undefined'){

  } else {
    calendar.destroy()
  }
})
