Individual =

  autocompleteIndividuals: ->
    $(document).on 'keyup', '#contact_query',  ->
      $.ajax
        url: '/contacts',
        dataType: "json",
        data:
          contact_query: $(this).val()
        beforeSend: ->
          $('#query_loading').show()
        success: (data) ->
            $('.individuals_pagination').fadeOut(200)
            $('#contact_listings').empty()
            data.forEach (element, index, array) ->
              $("<tr>")
                .append("<td>#{element.last_name}</td><td>#{element.first_name}</td><td>#{element.phone_number}</td><td><a href='mailto:#{element.email}'>#{element.email}</a></td>")
                .appendTo('#contact_listings');
            $('#query_loading').fadeOut('slow')

  resetIndividuals: ->
    $(document).on 'keyup', '#contact_query', (e) ->
      if e.keyCode == 8
        if $('#contact_query').val().length == 0
          $.ajax
            url: '/contacts',
            dataType: 'script'

Individual.autocompleteIndividuals()
Individual.resetIndividuals()
