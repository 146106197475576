ClientExpense = {
  addPayeeAddress: function(){
    $(document).on('click', 'add-payee-link', function(){
      $('#add-payee-address').trigger('click')
      $(this).attr('disabled', 'true')

    })
  },
  setupDynamicSearch: function(){
    $('#notes_client_expense_payee_id').select2()
    $('#notes_client_expense_payee_id').prepend(new Option('Add New Payee', 'new-payee', false, false))
    $(document).on('select2:select', '#notes_client_expense_payee_id', function(e){
      if (e.params.data.id == 'new-payee'){
        $.ajax({
          url: '/payees/new',
          dataType: 'script',
        })
      }
    })
  },
  dismissNewPayeeModal: function(){
    $(document).on('click','#dismiss-payee-modal-button', function(){
      $('#notes_client_expense_payee_id').val('').trigger('change')
    })
  }
}
