// # Place all the behaviors and hooks related to the matching controller here.
// # All this logic will automatically be available in application.js.
// # You can use CoffeeScript in this file: http://coffeescript.org/

ActivityPoller = {

  poll: function() {
    setTimeout(function(){ActivityPoller.request()}, 60000);
  },

  request: function(){
    $.get($('.activityFeed').data('url'), {after: $('.activity').first().data('id')})
  },

  addActivities: function(activities){
    if($(activities).length > 0){
      $('.activityFeed').prepend($(activities).hide())
      if ($('.activity').first().is(':hidden')){
        $('#showActivities').show()
      }
    }
    ActivityPoller.poll()
  },

  showActivities: function(e){
    e.preventDefault()
    $('.activity').show()
    $('#showActivities').hide()
  }
}


ActivityFeed = {

  load: function(location, html){
    if ($(location).find('.no_content').length > 0){
      $(location).html(html)
    } else {

    }
  },

  displayNewActivity: (html)=>{
    $('#activity-feed').prepend(html)
  }
}


$(document).on('turbolinks:load', function(){
  if ($('#activities_placeholder').length > 0){
    $.ajax({
      url: $('#activities_placeholder').data('url'),
      dataType: 'script'
    });
  }
  if ($('.activityFeed').length > 0){
    ActivityPoller.poll();
    $(document).on('click','#showActivities a', ActivityPoller.showActivities)
  }
});
